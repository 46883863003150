<template>
    <div>
        <ConfirmDialog ref="confirmRemove"/>

        <div class="addAction mb-3 me-3">
            <h4 class="form-title">Licenses</h4>
            <CButton color="primary" variant="outline" class="p-0" @click="showModal">Add License</CButton>
        </div>

        <SmartTable :name="'table-setting-account-company-details-licence'" :items="licenses"
                    :columns="licensesFields.map((i) => ({ ...i, sorter: false }))" infinity>
            <template #path="{ item }">
                <CIcon v-if="item.path" class="pointer" name="cil-description" @click="downloadDocument(item)"/>
            </template>
            <template #button="{ item }">
                <div class="d-flex">
                    <CIcon name="cil-pen" class="mx-2 pointer" @click="showModal($event, item)"/>
                    <CIcon name="cil-trash" class="mx-2 pointer" @click="handleRemove(item)"/>
                </div>
            </template>
        </SmartTable>

        <CompanyDetailsModal title="Add License" :visible="showForm" @close="() => (showForm = false)">
            <ConfirmDialog ref="confirmDialog"/>

            <div>
                <CNSelect v-model="form.subsidiary_company_id" required label="Select Company"
                          :options="subsidiaryCompaniesOptions"/>
            </div>

            <div>
                <CNSelect v-model="form.state_id" required label="Select State" :disabled="!states.length"
                          :invalid="!!validationErrors.state_id"
                          :options="states.map((s) => ({ ...s, value: s.state_id, name: s.state_name }))"
                          @blur="handleChangeField('state_id')"/>

                <CFormText v-if="validationErrors.state_id" :style="`color: ${validationErrors.state_id && 'red'}`">
                    {{ validationErrors.state_id }}
                </CFormText>
            </div>

            <CInputGroup>
                <CNInput v-model="form.number" required max-length="255" label="Enter Licence Number"
                         :invalid="!!validationErrors.number" @blur="handleChangeField('number')"/>
                <CFormText v-if="validationErrors.number" :style="`color: ${validationErrors.number && 'red'}`">
                    {{ validationErrors.number }}
                </CFormText>
            </CInputGroup>

            <CInputGroup>
                <CNInput v-model="form.type" required max-length="255" label="Enter Licence Class/Type"
                         :invalid="!!validationErrors.type" @blur="handleChangeField('type')"/>
                <CFormText v-if="validationErrors.type" :style="`color: ${validationErrors.type && 'red'}`">
                    {{ validationErrors.type }}
                </CFormText>
            </CInputGroup>

            <div>
                <CNInputDate v-model="form.exp_date" required :redesign="true" :start="start" :type="'clear'"
                             label="Expirations" :invalid="!!validationErrors.exp_date"
                             :error="validationErrors.exp_date" @touchField="handleChangeField('exp_date')"
                             @blur="handleChangeField('exp_date')"/>
            </div>

            <CInputGroup>
                <textarea v-model="form.note" placeholder="Enter Note" class="fl__textarea" maxlength="1000"
                          :class="{ fl_invalid: !!validationErrors.note }" @blur="handleChangeField('note')"/>
            </CInputGroup>

            <template #upload>
                <UploadFile v-model="form.path" :redesign="true" :file-icon="'cil-file'" :btn-icon="'cil-paperclip'"
                            :btn-name="'Attach license'"
                            :accept="'image/jpeg,image/png,.xls,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                            :with-validation="false" @change="handleChangeField('path')"/>
                <CFormText v-if="validationErrors.path" style="color: red">
                    {{ validationErrors.path }}
                </CFormText>
            </template>

            <template #footer>
                <CButton color="primary" variant="outline" class="w-100" @click="cancel"> Cancel</CButton>

                <CButton color="primary" class="w-100" @click="submit">
                    <CSpinner v-if="loading" color="light" size="sm"/>
                    Save
                </CButton>
            </template>
        </CompanyDetailsModal>
    </div>
</template>
<script>
import UploadFile from '@/components/UploadFile';
import validator from '@/utils/validator';
import rules from '@/utils/validator/rules';
import {parseError} from '@/utils/api';
import {equalObjectsByKeys} from '@/utils/helper';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import CompanyDetailsModal from '@/components/Modals/CompanyDetailsModal.vue';
import CNSelect from '@/components/ui/CNSelect/CNSelect.vue';
import CNInputDate from '@/components/ui/CNInputDate/CNInputDate.vue';
import documentDownload from "@/mixin/documentDownload";
import CompanyLicenses from "@/api/v2/endpoints/CompanyLicenses";

export default {
    name: 'Licence',
    components: {CNInputDate, CNSelect, CompanyDetailsModal, ConfirmDialog, UploadFile},
    inject: ['toast'],
    mixins: [documentDownload],
    props: {
        states: {type: Array, default: () => []},
        licenses: {type: Array, default: () => []},
        subsidiaryCompanies: {type: Array, default: () => []},
        company: {type: Number, required: true},
        defaultCompanyName: {type: String, required: true},
    },
    emits: ['updateData'],
    data() {
        return {
            loading: false,
            showForm: false,
            showAlert: false,
            error: '',
            licensesFields: [
                {key: 'company_name', label: 'Company'},
                {key: 'state_name', label: 'State'},
                {key: 'number', label: 'Licence Number'},
                {key: 'type', label: 'Licence Class/Type'},
                {
                    key: 'exp_date',
                    label: 'Expiration Date',
                    format: (value) => (value ? this.$BtoF({value}) : null),
                },
                {key: 'note', label: 'Notes'},
                {
                    key: 'path',
                    label: 'File',
                    _props: {class: 'tableIconCenter'},
                    _style: 'min-width: 10px',
                },
            ],
            form: {
                subsidiary_company_id: null,
                state_id: '',
                number: '',
                exp_date: '',
                type: '',
                note: '',
                path: '',
            },
            origin: {},
            validator: {},
            validationErrors: {},
        };
    },
    computed: {
        start() {
            return this.form.id ? null : new Date();
        },
        subsidiaryCompaniesOptions() {
            return [...this.subsidiaryCompanies, {value: 'COMPANY', name: this.defaultCompanyName}].map(
                (company) => ({
                    ...company,
                    value: company.id || company.value,
                }),
            );
        },
    },
    watch: {
        'form.state_id': function (state) {
            if (state === 'State') {
                this.form.state_id = '';
            }
        },
    },
    created() {
        this.$nextTick(() => this.setRules());
    },
    methods: {
        setRules() {
            this.validator = validator({
                state_id: [rules.required],
                number: [rules.required, rules.strMax(255)],
                exp_date: [rules.required],
                type: [rules.required, rules.strMax(255)],
                note: [rules.nullable, rules.strMax(255)],
                path: [
                    rules.nullable,
                    () => (typeof this.form.path === 'object' ? 0 : -1),
                    rules.extensions(['.xlsx', '.doc', '.docx', '.xls', '.pdf', '.jpeg', '.png', '.jpg']),
                    rules.fileSize({size: 10, type: 'MB'}),
                ],
            });
        },
        handleChangeField(field) {
            this.validationErrors[field] = this.validator.validate(field, this.form[field]);
        },
        getError(error) {
            this.error = error;
        },
        showModal(e, certificate = undefined) {
            e.preventDefault();

            this.handleShowForm(certificate);
        },
        handleShowForm(certificate = undefined) {
            if (certificate) {
                this.form = JSON.parse(JSON.stringify(certificate));

                if (!this.form.subsidiary_company_id) {
                    this.form.subsidiary_company_id = 'COMPANY';
                }
            } else {
                for (let key in this.form) {
                    this.form[key] = null;
                }
            }
            this.origin = JSON.parse(JSON.stringify(this.form));
            this.validationErrors = {};
            this.showForm = true;
        },
        checkForm() {
            return equalObjectsByKeys(this.form, this.origin, Object.keys(this.origin));
        },
        async cancel() {
            if (!this.checkForm()) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.showForm = false;
                            for (const key in this.form) {
                                this.form[key] = '';
                            }
                        }
                    });
            } else {
                this.showForm = false;
            }
        },
        makeFormData() {
            this.form.exp_date = this.$FtoB({value: this.form.exp_date, ms: true});
            const formData = new FormData();
            for (let key in this.form) {
                if (key === 'subsidiary_company_id' && this.form[key] === 'COMPANY') {
                    formData.append(key, '');
                } else {
                    formData.append(key, this.form[key] || '');
                }
            }
            return formData;
        },
        submit() {
            const validationResult = this.validator.validateAll(this.form);
            if (!validationResult.hasErrors && this.error === '') {
                this.loading = true;

                const action = this.form.id
                    ? this.$http.setting.updateCompanyLicense(this.form.id, this.makeFormData())
                    : this.$http.setting.addCompanyLicense(this.makeFormData());

                action
                    .then((response) => {
                        this.toast('success', response.data.message);
                        this.showForm = false;
                        this.$emit('updateData');
                    })
                    .catch((err) => {
                        err = parseError(err);
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages;
                        } else {
                            this.toast('error', err.message);
                        }
                    })
                    .finally(() => (this.loading = false));
            } else {
                this.validationErrors = validationResult.validationErrors;
            }
        },
        async handleRemove(item) {
            await this.$refs.confirmRemove
                .confirm({
                    text: 'Are you sure you want to delete license "' + item.number + '"?',
                    confirmText: this.$t('Delete'),
                    cancelText: this.$t('Cancel'),
                    reverse: true,
                })

                .then((res) => {
                    if (res) this.remove(item.id);
                });
        },
        remove(id) {
            this.$http.setting
                .removeCompanyLicense({license_id: id})
                .then((response) => {
                    this.toast('success', response.data.message);
                    this.$emit('updateData');
                })
                .catch((error) => {
                    error = parseError(error);
                    this.toast('error', error.message);
                });
        },
        downloadDocument(license) {
            this.handleDownloadPromise(
                CompanyLicenses.downloadFile(license.id),
                license.id,
                `License: ${license.number}`
            );
        },
    },
};
</script>
