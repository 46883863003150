<template>
  <CRow>
<!--    <CFormLabel-->
<!--      class="col-md-12 col-lg-5 col-xl-6 col-xxl-5 col-form-label"-->
<!--      style="font-size: 14px"-->
<!--    >-->
<!--      {{ label }}-->
<!--      <InfoIcon v-if="showTooltip" :tooltip-text="tooltipText" />-->
<!--    </CFormLabel>-->
    <div class="w-100">
      <div>
        <CNSelect
          :model-value="modelValue"
          :caret="true"
          :label="label"
          :searchable="true"
          :options="options.map(r => ({ ...r, name: r.full_name, value: r.id }))"
          @blur="changeValue"
        />

<!--        <CFormSelect-->
<!--          :model-value="modelValue"-->
<!--          class="col-sm-12"-->
<!--          :search="true"-->
<!--          :loading="loading"-->
<!--          :disabled="loading"-->
<!--          :style="`border-color: ${error && 'red'}`"-->
<!--          @change="changeValue"-->
<!--          @blur="changeValue"-->
<!--        >-->
<!--          <option :disabled="disablePlaceholder">{{ placeholder }}</option>-->
<!--          <option-->
<!--            v-for="(option, index) in options"-->
<!--            :key="index"-->
<!--            :value="option.id"-->
<!--            :selected="parseInt(modelValue) === option.id"-->
<!--          >-->
<!--            {{ option.full_name }}-->
<!--          </option>-->
<!--        </CFormSelect>-->

      </div>
      <CFormText v-if="error" style="color: red">
        {{ error }}
      </CFormText>
    </div>
  </CRow>
</template>

<script>
import InfoIcon from './InfoIcon'
import CNSelect from "@/components/ui/CNSelect/CNSelect.vue";
export default {
  name: 'LoadingSelect',
  components: {CNSelect, InfoIcon },
  props: {
    modelValue: null,
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    tooltipText: { type: String, default: 'Content' },
    showTooltip: { type: Boolean, default: true },
    disablePlaceholder: { type: Boolean, default: false },
    require: { type: Boolean, default: false },
    error: { type: String, default: '' },
    roles: { type: Array, default: () => [] },
    permission: { type: String, default: () => undefined },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      loading: false,
      options: [],
    }
  },
  mounted() {
    this.execute()
  },
  methods: {
    changeValue(value) {
      this.$emit('update:modelValue', parseInt(value))
      this.$emit('change')
    },
    execute() {
      this.loading = true
      this.$http.companies
        .getCompanyUsers({
          params: {
            search: '',
            roles: this.roles,
            permission: this.permission,
          },
        })
        .then((response) => {
          this.options = response.data.data
        })
        .catch((errors) => {
          console.log(errors)
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>
