<template>
    <div>
        <ConfirmDialog ref="confirmRemove"/>
        <div class="addAction mb-3 me-3">
            <h4 class="form-title">Certifications</h4>

            <CButton color="primary" variant="outline" class="p-0" @click="showModal"> Add Certification</CButton>
        </div>
        <SmartTable :name="'table-setting-account-company-details-certification'" :items="certifications"
                    :columns="certificationsFields.map((i) => ({ ...i, sorter: false }))" infinity>
            <template #path="{ item }">
                <CIcon v-if="item.path" variant="ghost" class="pointer" name="cil-description"
                       @click="downloadFile(item)"/>
            </template>
            <template #button="{ item }">
                <div class="d-flex">
                    <CIcon name="cil-pen" class="mx-2 pointer" @click="showModal($event, item)"/>
                    <CIcon name="cil-trash" class="mx-2 pointer" @click="handleRemove(item)"/>
                </div>
            </template>
        </SmartTable>

        <CompanyDetailsModal :visible="showForm" title="Add Certification"
                             text="Browse and attach the type of certifications to provide information about the health and the capabilities of your company."
                             @close="() => (showForm = false)">
            <ConfirmDialog ref="confirmDialog"/>

            <CInputGroup>
                <CNInput v-model="form.name" required max-length="255" label="Certification Name"
                         :invalid="!!validationErrors.name" @blur="handleChangeField('name')"/>
                <CFormText v-if="validationErrors.name" :style="`color: ${validationErrors.name && 'red'}`">
                    {{ validationErrors.name }}
                </CFormText>
            </CInputGroup>

            <CInputGroup>
                <CNInput v-model="form.type" required max-length="255" label="Type" :invalid="!!validationErrors.type"
                         @blur="handleChangeField('type')"/>
                <CFormText v-if="validationErrors.type" :style="`color: ${validationErrors.type && 'red'}`">
                    {{ validationErrors.type }}
                </CFormText>
            </CInputGroup>

            <CInputGroup>
                <CNInput v-model="form.number" required max-length="255" :mask="['XXXX-XXXXXX']"
                         label="Certification Number" :invalid="!!validationErrors.number"
                         @blur="handleChangeField('number')"/>
                <CFormText v-if="validationErrors.number" :style="`color: ${validationErrors.number && 'red'}`">
                    {{ validationErrors.number }}
                </CFormText>
            </CInputGroup>

            <div>
                <CNInputDate v-model="form.exp_date" required :start="start" :type="'clear'" :redesign="true"
                             label="Expiration Date" :invalid="!!validationErrors.exp_date"
                             :error="validationErrors.exp_date" @touchField="handleChangeField('exp_date')"
                             @focusout="handleChangeField('exp_date')"/>
            </div>

            <CInputGroup>
                <CNInput v-model="form.agency" required max-length="255" label="Agency Name"
                         :invalid="!!validationErrors.agency" @blur="handleChangeField('agency')"/>
                <CFormText v-if="validationErrors.agency" :style="`color: ${validationErrors.agency && 'red'}`">
                    {{ validationErrors.agency }}
                </CFormText>
            </CInputGroup>

            <CInputGroup>
                <textarea v-model="form.note" placeholder="Enter Note" class="fl__textarea" maxlength="1000"
                          :class="{ fl_invalid: !!validationErrors.note }" @blur="handleChangeField('note')"/>

                <CFormText v-if="validationErrors.note" :style="`color: ${validationErrors.note && 'red'}`">
                    {{ validationErrors.note }}
                </CFormText>
            </CInputGroup>

            <template #upload>
                <CCol class="px-0 col-12">
                    <UploadFile v-model="form.path" :redesign="true" :file-icon="'cil-file'" :btn-icon="'cil-paperclip'"
                                :btn-name="'Attach certificate'" :with-validation="false"
                                :accept="'image/jpeg,image/png,.xls,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                @change="handleChangeField('path')"/>
                    <CFormText v-if="validationErrors.path" style="color: red">
                        {{ validationErrors.path }}
                    </CFormText>
                </CCol>
            </template>

            <template #footer>
                <CButton variant="outline" color="primary" class="w-100" @click="cancel"> Cancel</CButton>

                <CButton color="primary" class="w-100" @click="submit">
                    <CSpinner v-if="loading" color="light" size="sm"/>
                    Save
                </CButton>
            </template>
        </CompanyDetailsModal>
    </div>
</template>
<script>
import UploadFile from '@/components/UploadFile';
import validator from '@/utils/validator';
import rules from '@/utils/validator/rules';
import {parseError} from '@/utils/api';
import {equalObjectsByKeys} from '@/utils/helper';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import CompanyDetailsModal from '@/components/Modals/CompanyDetailsModal.vue';
import CNInputDate from '@/components/ui/CNInputDate/CNInputDate.vue';
import CompanyCertifications from "@/api/v2/endpoints/CompanyCertifications";
import documentDownload from "@/mixin/documentDownload";

export default {
    name: 'Certification',
    components: {CNInputDate, CompanyDetailsModal, UploadFile, ConfirmDialog},
    inject: ['toast'],
    mixins: [documentDownload],
    props: {
        certifications: {type: Array, default: () => []},
    },
    emits: ['updateData'],
    data() {
        return {
            error: '',
            loading: false,
            showConfirmed: false,
            showForm: false,
            certificationsFields: [
                {key: 'name', label: 'Certifications'},
                {key: 'type', label: 'Type'},
                {key: 'number', label: 'Cert. #'},
                {
                    key: 'exp_date',
                    label: 'Expirations',
                    format: (value) => this.$BtoF({value}),
                },
                {key: 'agency', label: 'Agency'},
                {key: 'note', label: 'Note'},
                {
                    key: 'path',
                    label: 'File',
                    _props: {class: 'tableIconCenter'},
                    _style: 'min-width: 10px',
                },
            ],
            form: {
                name: '',
                type: '',
                number: '',
                exp_date: '',
                agency: '',
                note: '',
                file: '',
                path: '',
            },
            checkedKeys: ['name', 'type', 'number', 'exp_date', 'agency', 'note'],
            origin: {},
            validator: {},
            validationErrors: {},
        };
    },
    computed: {
        start() {
            return this.form.id ? null : new Date();
        },
    },
    created() {
        this.$nextTick(() => this.setRules());
    },
    methods: {
        setRules() {
            this.validator = validator({
                name: [rules.required, rules.strMax(255)],
                type: [rules.required, rules.strMax(255)],
                number: [rules.required, rules.strMax(255)],
                exp_date: [rules.required],
                agency: [rules.required, rules.strMax(255)],
                note: [rules.nullable, rules.strMax(255)],
                path: [
                    rules.nullable,
                    () => (typeof this.form.path === 'object' ? 0 : -1),
                    rules.extensions(['.xlsx', '.doc', '.docx', '.xls', '.pdf', '.jpeg', '.png', '.jpg']),
                    rules.fileSize({size: 10, type: 'MB'}),
                ],
            });
        },
        getError(error) {
            this.error = error;
        },
        handleChangeField(field) {
            this.validationErrors[field] = this.validator.validate(field, this.form[field]);
        },
        showModal(e, certificate = undefined) {
            e.preventDefault();
            if (certificate) {
                this.form = JSON.parse(JSON.stringify(certificate));
            } else {
                for (let key in this.form) {
                    this.form[key] = null;
                }
            }
            this.origin = JSON.parse(JSON.stringify(this.form));
            this.validationErrors = {};
            this.showForm = true;
        },
        checkForm() {
            return equalObjectsByKeys(this.form, this.origin, Object.keys(this.origin));
        },
        async cancel() {
            if (!this.checkForm()) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.showForm = false;
                            for (const key in this.form) {
                                this.form[key] = '';
                            }
                        }
                    });
            } else {
                this.showForm = false;
            }
        },
        makeFormData() {
            this.form.exp_date = this.$FtoB({value: this.form.exp_date, ms: true});
            const formData = new FormData();
            for (let key in this.form) {
                formData.append(key, this.form[key] || '');
            }
            return formData;
        },
        submit() {
            const validationResult = this.validator.validateAll(this.form);
            if (!validationResult.hasErrors && this.error === '') {
                this.loading = true;
                const action = this.form.id
                    ? this.$http.setting.updateCompanyCertification(this.form.id, this.makeFormData())
                    : this.$http.setting.addCompanyCertification(this.makeFormData());

                action
                    .then((response) => {
                        this.toast('success', response.data.message);
                        this.showForm = false;
                        this.$emit('updateData');
                    })
                    .catch((err) => {
                        err = parseError(err);
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages;
                        } else {
                            this.toast('error', err.message);
                        }
                    })
                    .finally(() => (this.loading = false));
            } else {
                this.validationErrors = validationResult.validationErrors;
            }
        },
        async handleRemove(item) {
            await this.$refs.confirmRemove
                .confirm({
                    text: 'Are you sure you want to delete certification "' + item.name + '"?',
                    confirmText: this.$t('Delete'),
                    cancelText: this.$t('Cancel'),
                    reverse: true,
                })

                .then((res) => {
                    if (res) this.remove(item.id);
                });
        },
        remove(id) {
            this.$http.setting
                .removeCompanyCertification({certification_id: id})
                .then((response) => {
                    this.toast('success', response.data.message);
                    this.$emit('updateData');
                })
                .catch((error) => {
                    error = parseError(error);
                    this.toast('error', error.message);
                });
        },
        downloadFile(certification) {
            this.handleDownloadPromise(
                CompanyCertifications.downloadFile(certification.id),
                certification.id,
                `Certification: ${certification.name}`
            );
        },
    },
};
</script>
<style></style>
