<template>
    <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem active> Company Details</CBreadcrumbItem>
    </teleport>

    <div class="company-details">
        <div class="company-details__content">
            <div>
                <h1 class="company-details__title">Company Details</h1>
                <p class="company-details__desc">
                    Company information will appear in your public profile.
                    <router-link v-if="form.id" class="mx-2" :to="{ name: 'Company', params: { id: form.id } }">
                        View Profile
                    </router-link>
                </p>
            </div>

            <transition name="fade">
                <div v-if="formAvailable" class="company-details__form">
                    <div class="company-details__form-avatar">
                        <LogoInput :size="150" :image-url="form.logo" :display-name="[form.name]" is-company-logo
                                   class="uploadLogo" upload-text="Upload Photo" :preview-rounded="false"
                                   @input="showFile" @click="() => (validationErrors.logo = '')">
                            <template #error>
                                <CFormText v-if="validationErrors.logo" tyle="color: red">
                                    {{ validationErrors.logo }}
                                </CFormText>
                            </template>
                        </LogoInput>
                    </div>

                    <div class="company-details__form-fields">
                        <div class="company-details__form-item">
                            <CNInput v-model="form.name" label="Company Name" max-length="255"
                                     :invalid="!!validationErrors.name" @blur="handleChangeField('name')"/>
                            <CFormText v-if="validationErrors.name" style="color: red">
                                {{ validationErrors.name }}
                            </CFormText>
                        </div>

                        <div class="company-details__form-item">
                            <CNInput v-model="form.dba" label="DBA" max-length="255" :invalid="!!validationErrors.dba"
                                     @blur="handleChangeField('dba')"/>
                            <CFormText v-if="validationErrors.dba" style="color: red">
                                {{ validationErrors.dba }}
                            </CFormText>
                        </div>

                        <div class="company-details__form-item">
                            <CNInput v-model="form.website" label="Website" max-length="255"
                                     :invalid="!!validationErrors.website" @blur="handleChangeField('website')"/>
                            <CFormText v-if="validationErrors.website" style="color: red">
                                {{ validationErrors.website }}
                            </CFormText>
                        </div>

                        <div class="company-details__form-item">
                            <TaxId v-model="form.tid" v-model:verified="form.verified_tid" :company-id="form.id"
                                   :error="validationErrors.tid" @blurValid="handleChangeField"/>
                        </div>

                        <CButton :aria-disabled="true" variant="outline" color="primary"> Verify your ID</CButton>
                    </div>

                    <div class="company-details__form-fields">
                        <div class="company-details__form-item">
                            <CNSelect v-if="companyUsersLead.length" v-model="form.primary_contact_id"
                                      label="Office Lead"
                                      :options="companyUsersLead.map((r) => ({ ...r, name: r.full_name, value: r.id }))"/>
                            <CFormText v-if="validationErrors.primary_contact_id" style="color: red">
                                {{ validationErrors.primary_contact_id }}
                            </CFormText>
                        </div>

                        <div class="company-details__form-item">
                            <CNSelect v-if="companyUsersSigners.length" v-model="form.default_contract_signer_id"
                                      label="Default Contract Signer"
                                      :options="companyUsersSigners.map((r) => ({ ...r, name: r.full_name, value: r.id }))"/>
                            <CFormText v-if="validationErrors.default_contract_signer_id" style="color: red">
                                {{ validationErrors.default_contract_signer_id }}
                            </CFormText>
                        </div>

                        <div class="company-details__form-item">
                            <CNSelect v-if="companyUsersAssigners.length" v-model="form.default_task_assignee_id"
                                      label="Default Task Assignee"
                                      :options=" companyUsersAssigners.map((r) => ({ ...r, name: r.full_name, value: r.id }))"/>
                            <CFormText v-if="validationErrors.default_task_assignee_id" style="color: red">
                                {{ validationErrors.default_task_assignee_id }}
                            </CFormText>
                        </div>

                        <div class="company-details__form-item">
                            <h3 class="company-details__types-title">Company Type</h3>

                            <div class="company-details__types">
                                <div class="w-100 d-flex flex-wrap">
                                    <div v-for="(type, index) in formCompanyTypes" :key="index"
                                         class="form-check company-details__types-box">
                                        <input v-model="type.enabled" class="form-check-input" type="checkbox"/>
                                        <label class="form-check-label">
                                            {{ type.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <div v-if="!formAvailable" class="company-details__loading">
                <CSpinner color="primary"/>
            </div>

            <div class="company-details__tables">
                <div class="company-details__tables-item">
                    <Certification v-model:certifications="certifications" @updateData="fetchData(true)"/>
                </div>
                <div class="company-details__tables-item">
                    <Licence v-model:licenses="licenses" :company="form.id" :subsidiary-companies="subsidiaryCompanies"
                             :states="states" :default-company-name="defaultCompanyName" @updateData="fetchData(true)"/>
                </div>
                <div class="company-details__tables-item">
                    <SubsidiaryCompanies v-model:subsidiary-companies="subsidiaryCompanies"
                                         :company-primary-contacts="companyPrimaryContacts"
                                         :company-default-signers-or-assigners="companyDefaultSignersOrAssigners"
                                         @updateData="fetchData(true)"/>
                </div>
                <div class="company-details__tables-item">
                    <Office ref="office" :offices="offices" :countries="countries" @updateData="fetchData(true)"/>
                </div>
            </div>

            <StickyFooter>
                <CButton color="primary" variant="outline" @click="cancel"> Cancel</CButton>
                <CButton color="primary" @click="submit"> Update</CButton>
            </StickyFooter>

            <ConfirmDialog ref="confirmDialog"/>
        </div>
    </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
.company-details {
    background: white;
    padding: 24px;
    border-radius: 8px;

    &__loading {
        display: flex;
        justify-content: center;
        padding: 100px 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 56px;
    }

    &__tables {
        display: flex;
        flex-direction: column;
        gap: 36px;

        &-item {
            padding-top: 24px;
            border-top: 1px solid #dee4e8;
        }
    }

    &__form {
        display: flex;
        gap: 24px;
        max-width: 1115px;

        &-item {
            width: 100%;
        }

        &-avatar {
            width: 150px;
            flex-shrink: 0;
        }

        &-fields {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 24px;
            flex: 1;
        }
    }

    &__types {
        display: flex;

        &-box {
            margin-bottom: 16px;
            color: #1c262f;
            font-size: 14px !important;
            width: 50%;

            & input {
                transform: scale(1.1);
                border: 1px solid #677a89;
                border-radius: 2px;
            }
        }

        &-title {
            font-size: 16px;
            color: #000000;
            margin-bottom: 40px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 0;
                height: 1px;
                width: 100%;
                background: #0081c2;
            }
        }
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        color: #1c262f;
    }

    &__desc {
        color: #1c262f;
        font-size: 16px;
    }
}

@import 'style';
</style>
